// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "f_mt";
export var warningBackgrounddark = "f_mv";
export var warningBackgroundcustom = "f_mw";
export var bottom = "f_gF";
export var modal = "f_mx";
export var container = "f_my";
export var btnWrapper = "f_dZ";
export var elementWrapper = "f_mz";
export var titleWrapper = "f_mB";
export var contentWrapper = "f_mC";
export var btn = "f_mD";
export var link = "f_mF";
export var decline = "f_mG f_mD";
export var editor = "f_mH";
export var row = "f_mJ";