const handleNavResize = (navs, breakWidth, wrapped, setWrapped, setBreakWidth, w, layout, links) => {
  const width = w || window.innerWidth;

  const left = { total: 0, text: '', length: 0 };
  const right = { total: 0, text: '', length: 0 };

  let length = 0;
  let text;
  let linksLeft;

  if (layout === 'divided') linksLeft = Math.floor(links.length / 2);
  // Find the link with the longest text (it will wrap first)
  links.forEach((link, i) => {
    const linkLength = (link.text || link.name || '').length;
    if (layout === 'divided' && i < linksLeft) {
      left.total += linkLength;
      if (linkLength > left.length) {
        left.text = link.text || link.name || '';
        left.length = linkLength;
      }
    } else if (layout === 'divided') {
      right.total += linkLength;
      if (linkLength > right.length) {
        right.text = link.text || link.name || '';
        right.length = linkLength;
      }
    } else if (linkLength > length) {
      text = link.text || link.name || '';
      length = linkLength;
    }
  });

  let containerIdx = 0;
  if (layout === 'divided') {
    text = left.text;
    if (right.total > left.total) {
      containerIdx = 2;
      text = right.text;
    }
  }

  const el = navs.current?.children?.[0];
  const nav = ['left', 'right'].includes(layout) ? 'combinedNavs' : layout === 'divided' ? 'navDivided' : 'navCenter';
  const trgtIdx = Array.from(el?.children)?.findIndex((child) => child.id === nav);

  let container = el?.children?.[trgtIdx]?.children?.[containerIdx]?.children;
  if (layout === 'center') container = el?.children?.[trgtIdx]?.children;

  let idx;
  if (container) {
    [...container]?.some((child, i) => {
      if (child?.children?.[0]?.innerHTML === text) idx = i;

      return idx !== undefined;
    });
  }

  const trgt = container?.[idx]?.children?.[0];

  let lineHeightParsed;
  if (trgt) {
    lineHeightParsed = parseInt(getComputedStyle(trgt)?.lineHeight?.split('px')?.[0], 10);
  }

  if (breakWidth) {
    // Wrapping width has already been determined
    let wrap = breakWidth.width != null ? width <= breakWidth.width : false;

    // Update breakWidth width while the value hasn't been frozen yet
    if (lineHeightParsed !== undefined && breakWidth.freeze !== true) {
      const value = { ...breakWidth };
      if (!value.startAsBurger && trgt.offsetHeight >= lineHeightParsed * 2) {
        // Freeze when wraps
        value.freeze = true;
        value.width = width;
        wrap = true;
      } else if (value.startAsBurger) {
        // Freeze when unwraps
        value.width = width;
        wrap = false;
        value.freeze = trgt.offsetHeight < lineHeightParsed * 2;
      }
      setBreakWidth(value);
    }

    if (wrap !== wrapped.wrap) setWrapped({ initialized: true, wrap });
  } else if (trgt) {
    // Wrapping hasn't happened yet
    const wrap = trgt.offsetHeight >= lineHeightParsed * 2;
    if (wrap !== wrapped.wrap) {
      setBreakWidth({ width, startAsBurger: true });
      setWrapped({ initialized: true, wrap });
    } else {
      setBreakWidth({ startAsBurger: false });
      if (!wrapped.initialized) setWrapped({ initialized: true, wrap });
    }
  } else if (!wrapped.initialized) setWrapped({ ...wrapped, initialized: true });
};

export default handleNavResize;
